<template>
  <div>

    <!-- modal -->
    <b-modal
      id="modal-primary"
      :ok-only="false"
      ok-title="บันทึกข้อมูล"
      cancel-title="ยกเลิก"
      modal-class="modal-primary"
      :title="_title"
      size="sm"
      @ok="clickSave"
      @cancel="clickCancel"
    >
      <b-card-text>
        <validation-observer ref="formp">
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="รหัสอ้างอิง"
                  label-for="a-code"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="code"
                    rules="required|min:5"
                  >
                    <b-form-input
                      id="a-code"
                      v-model="form.code"
                      :state="errors.length > 0 ? false:null"
                      type="text"
                      placeholder="กรอกรหัสอ้างอิง"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- price -->
              <b-col cols="12">
                <b-form-group
                  label="ราคา"
                  label-for="a-price"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="price"
                    rules="required|numeric"
                  >
                    <b-form-input
                      id="a-price"
                      v-model="form.price"
                      :state="errors.length > 0 ? false:null"
                      type="number"
                      placeholder="กรอก ราคา"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="เครดิต"
                  label-for="a-credit"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="credit"
                    rules="required|numeric"
                  >
                    <b-form-input
                      id="a-credit"
                      v-model="form.credit"
                      :state="errors.length > 0 ? false:null"
                      type="number"
                      placeholder="กรอก เครดิต"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, VBModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, numeric, double } from '@validations'
const f = {
  id: '',
  code: '',
  price: '',
  credit: '',
}
export default {
  data() {
    return {
      required,
      numeric,
      double,

      form: this.cp(f),
    }
  },
  computed: {
    _title() {
      return `${this.form.id ? 'แก้ไข' : 'เพิ่ม'} ข้อมูลราคา`
    },
  },
  components: {
    BButton,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  methods: {
    show(data = '') {
      if (data) this.form = data
      else this.form = this.cp(f)
      this.$bvModal.show('modal-primary')
    },
    async clickSave(data) {
      data.preventDefault()
      const validate = await this.$refs.formp.validate()
      if (!validate) return ''

      const resp = await this.api.createOrUpdate('/api/admin/admin-topic-credit-data', this.form)
      Object.keys(this.form).forEach(field => (this.form[field] = ''))
      this.$bvModal.hide('modal-primary')
      this.$emit('reload', '')
    },
    clickCancel() {},
  },
}
</script>